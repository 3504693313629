.carta {
  width: 25%;
  height: 80px;
  margin-top: 20px !important;
  margin-bottom: 100px;
}

.portada {
  width: 100%;
  height: 80px;
  background-color: #ffb300;
}

.contenido {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(3, 220, 244);
  font-size: 10px;
}

.ReactFlipCard {
  width: 100%;
  height: 100%;
}